import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useWallet } from '../components/WalletContext';
import Spinner from '../components/Spinner';
import '../styles/CollectionDetailPage.css';
import { getPrimaryColorFromImage } from '../utils/colorUtils'; // A new utility function for color extraction

// Import images
import skyGalleryImage from '../assets/skygallery.png';
import klevernftImage from '../assets/klever.png';
import minthreeImage from '../assets/minthree.png';
import xportImage from '../assets/xport.png';
import RareCanvasImage from '../assets/logoicon2.png';
import kDEXImage from '../assets/kdex.png';
import world from '../assets/world.png';
import artGallery from '../assets/artgallery.png';
import telegramLogo from '../assets/telegramlogo.png';
import websiteLogo from '../assets/websitelogo.png';
import discordLogo from '../assets/discordlogo.png';

const marketplaceNames = {
  '417b70c0eb7a33cb': 'Rare Canvas',
  'd4f2bab340c55fde': 'Klever NFT',
  'aa68ad853a09988c': 'Xport',
  'd2a04fe890a6edda': 'NFT Art Gallery',
  '2936933ee43db509': 'Minthree',
  '7c353c02770da029': 'Sky Gallery',
  '116056b257d9f6d5': 'kDEX',
  '81376f526cf47730': 'World dex'
};

const marketplaceImages = {
  '7c353c02770da029': skyGalleryImage,
  'd4f2bab340c55fde': klevernftImage,
  '2936933ee43db509': minthreeImage,
  'aa68ad853a09988c': xportImage,
  '417b70c0eb7a33cb': RareCanvasImage,
  '116056b257d9f6d5': kDEXImage,
  '81376f526cf47730': world,
  'd2a04fe890a6edda': artGallery,
};

const CollectionDetailPage = () => {
  const { collectionId } = useParams();
  const { walletAddress } = useWallet();
  
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [activeTab, setActiveTab] = useState('items');
  const [activity, setActivity] = useState([]);
  const [nftPrices, setNftPrices] = useState({});
  const [logoUrl, setLogoUrl] = useState('');
  const [assetName, setAssetName] = useState('');
  const [totalVolume, setTotalVolume] = useState(0);
  const [floorPrice, setFloorPrice] = useState(0);
  const [items, setItems] = useState(0);
  const [owners, setOwners] = useState(0);
  const [selectedMarketplace, setSelectedMarketplace] = useState('417b70c0eb7a33cb');
  const [telegramUri, setTelegramUri] = useState('');
  const [websiteUri, setWebsiteUri] = useState('');
  const [discordUri, setDiscordUri] = useState('');
  const [bannerUri, setBannerUri] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [cache, setCache] = useState({});
  const [activityPage, setActivityPage] = useState(1);
  const [totalActivityPages, setTotalActivityPages] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState('sales');
  const [transactions, setTransactions] = useState([]);
  const [transactionPage, setTransactionPage] = useState(1);
  const [totalTransactionPages, setTotalTransactionPages] = useState(0);
  const [traits, setTraits] = useState({});
  const [selectedTraits, setSelectedTraits] = useState({});
  const [sortOrder, setSortOrder] = useState('default');
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [marketplaceDropdownVisible, setMarketplaceDropdownVisible] = useState(false);
  const [gradientBackground, setGradientBackground] = useState(''); // New state for gradient background


  useEffect(() => {
    fetchData(currentPage);
    fetchCollectionStats();
    fetchActivityData();
    fetchTransactions();
  }, [collectionId, currentPage, activityPage, transactionPage, selectedMarketplace, sortOrder]);

  useEffect(() => {
    setCurrentPage(1);
    setActivityPage(1);
    setTransactionPage(1);
  }, [activeTab]);

  useEffect(() => {
    if (logoUrl && !bannerUri) {
      generateGradientFromLogo(logoUrl);
    }
  }, [logoUrl, bannerUri]);

  const fetchAssetList = async () => {
    try {
      const url = 'https://api.mainnet.klever.finance/v1.0/assets/list?limit=100';
      const responsePage1 = await axios.get(`${url}&page=1`);
      const responsePage2 = await axios.get(`${url}&page=2`);
  
      const combinedAssets = [...responsePage1.data.data.assets, ...responsePage2.data.data.assets];
      const matchingAsset = combinedAssets.find(asset => asset.assetId === collectionId);
      
      if (matchingAsset) {
        setAssetName(matchingAsset.name);
        setTelegramUri(extractUri(matchingAsset, 'telegram'));
        setWebsiteUri(extractUri(matchingAsset, 'website'));
        setDiscordUri(extractUri(matchingAsset, 'discord'));
        setBannerUri(extractUri(matchingAsset, 'banner'));
        setLogoUrl(modifyLogoUrl(matchingAsset.logo));
      }
      
      return matchingAsset;
    } catch (error) {
      console.error('Error fetching asset list:', error);
      return null;
    }
  };

  const extractUri = (asset, key) => {
    const uriObject = asset.uris.find(uri => uri.key.toLowerCase() === key.toLowerCase());
    return uriObject ? uriObject.value : null;
  };

  const modifyLogoUrl = (logoUri) => {
    if (logoUri && logoUri.includes("klever-mint.mypinata.cloud")) {
      return logoUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
    }
    return logoUri;
  };

  const generateGradientFromLogo = async (logoUrl) => {
    try {
      const colors = await getPrimaryColorFromImage(logoUrl);
      if (colors && colors.length > 0) {
        const gradient = `linear-gradient(45deg, ${colors.join(', ')})`;
        setGradientBackground(gradient);
      }
    } catch (error) {
      console.error('Error generating gradient from logo:', error);
    }
  };

  const fetchMetadata = async (asset, nonce) => {
    if (cache[nonce]) {
      setImageUrl(cache[nonce].image);
      return cache[nonce];
    }

    try {
      let metadataUri = getMetadataUri(asset, nonce);
      if (!metadataUri) return null;

      const response = await fetch(metadataUri);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      const metadata = await response.json();
      metadata.image = modifyImageUrl(metadata.image);
      setImageUrl(metadata.image);
      const price = await fetchNftPrice(`${collectionId}/${nonce}`);

      setCache(prevCache => ({ ...prevCache, [nonce]: { ...metadata, price: price } }));
      
      // Extract traits
      const extractedTraits = {};
      if (metadata.attributes) {
        metadata.attributes.forEach(attribute => {
          if (!extractedTraits[attribute.trait_type]) {
            extractedTraits[attribute.trait_type] = new Set();
          }
          extractedTraits[attribute.trait_type].add(attribute.value);
        });
      }

      setTraits(prevTraits => {
        const updatedTraits = { ...prevTraits };
        Object.entries(extractedTraits).forEach(([key, values]) => {
          if (!updatedTraits[key]) {
            updatedTraits[key] = new Set();
          }
          values.forEach(value => updatedTraits[key].add(value));
        });
        return updatedTraits;
      });

      return { ...metadata, price: price };
    } catch (error) {
      console.error(`Error fetching metadata for nonce ${nonce}:`, error);
      return null;
    }
  };

  const getMetadataUri = (asset, nonce) => {
    const metadataObject = asset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
    let metadataUri = metadataObject ? metadataObject.value : null;

    if (metadataUri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") {
      metadataUri = `https://ipfs.io/ipfs/${metadataUri}/${nonce}.json`;
    } else if (metadataUri) {
      if (metadataUri.includes("klever-mint.mypinata.cloud")) {
        metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
      }
      metadataUri = `${metadataUri}/${nonce}`;
    }
    return metadataUri;
  };

  const modifyImageUrl = (imageUri) => {
    if (imageUri && imageUri.includes("www.lovemonsternft.com")) {
      const imageName = imageUri.split('/').pop();
      return `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
    } else if (imageUri && imageUri.includes("klever-mint.mypinata.cloud")) {
      return imageUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
    }
    return imageUri;
  };

  const fetchNftPrice = async (uniqueNftId) => {
    try {
      const url = `https://api.mainnet.klever.finance/v1.0/marketplaces/nfts/activity?nftId=${uniqueNftId}&sort=desc&limit=10&page=1`;
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      const data = await response.json();
      const rawPriceData = data.data.history[0]?.contract[0]?.parameter?.price;
      if (rawPriceData) {
        const price = rawPriceData / 1000000;
        const formattedPrice = Number.isInteger(price) ?
          price.toLocaleString('en-US') :
          price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 6 });
        setNftPrices(prevPrices => ({ ...prevPrices, [uniqueNftId]: formattedPrice }));
        return formattedPrice;
      }
    } catch (error) {
      console.error('Error fetching NFT price:', error);
    }
  };

  const updateMetadataForNonce = (nonce, metadata) => {
    setNfts(prevNfts => prevNfts.map(nft => nft.nonce === nonce ? { ...nft, metadata, loadingMetadata: false } : nft));
  };

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const asset = await fetchAssetList();
      if (!asset) {
        setLoading(false);
        return;
      }

      const response = await axios.get(`https://api.mainnet.klever.finance/marketplaces/${selectedMarketplace}?collection=${collectionId}&price=desc&limit=50&page=${page}`);
      const assetsData = response.data.data.assets.assets;

      const initialNfts = Object.keys(assetsData).map(key => {
        const assetDetails = assetsData[key];
        return { nonce: assetDetails.nftNonce, metadata: null, loadingMetadata: true, orderId: assetDetails.orderId };
      });

      setNfts(initialNfts);
      setTotalPages(response.data.pagination.totalPages);
      
      initialNfts.forEach(async nft => {
        try {
          const metadata = await fetchMetadata(asset, nft.nonce);
          const transactionResponse = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?orderid=${nft.orderId}`);
          const transactions = transactionResponse.data.data.transactions;
          const now = Math.floor(Date.now() / 1000);
          const isExpired = transactions.some(transaction => transaction.contract.some(contract => contract.parameter.endTime <= now));
          updateMetadataForNonce(nft.nonce, {...metadata, expired: isExpired, orderId: nft.orderId});
        } catch (error) {
          console.error(`Error fetching data for nonce ${nft.nonce}:`, error);
          updateMetadataForNonce(nft.nonce, {metadata: null, expired: false, orderId: nft.orderId});
        }
      });
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    }
    setLoading(false);
  };

  const fetchCollectionStats = async () => {
    try {
      const marketplaceIds = ['d4f2bab340c55fde', '7c353c02770da029', '2936933ee43db509', '417b70c0eb7a33cb', '116056b257d9f6d5', 'aa68ad853a09988c', '81376f526cf47730', 'd2a04fe890a6edda'];
      const statsPromises = marketplaceIds.map(id =>
        axios.get(`https://api.mainnet.klever.finance/v1.0/marketplaces/${id}/stats`)
      );
  
      const statsResponses = await Promise.all(statsPromises);
      
      let totalVolume = 0;
      let lowestFloorPrice = Infinity;
      let totalItems = 0;
      let totalOwners = 0;
  
      statsResponses.forEach(response => {
        const statsData = response.data.data.stats;
        const collectionStats = statsData.find(stat => stat.assetId === collectionId);
  
        if (collectionStats) {
          totalVolume += collectionStats.volumeTotal || 0;
          lowestFloorPrice = Math.min(lowestFloorPrice, collectionStats.floor || Infinity);
          totalItems += collectionStats.items || 0;
          totalOwners += collectionStats.owners || 0;
        }
      });
  
      if (lowestFloorPrice === Infinity) lowestFloorPrice = 0;
  
      setTotalVolume(totalVolume);
      setFloorPrice(lowestFloorPrice);
      setItems(totalItems);
      setOwners(totalOwners);
    } catch (error) {
      console.error('Error fetching collection stats:', error);
    }
  };

  const fetchActivityData = async () => {
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=10&type=18&status=success&asset=${collectionId}&page=${activityPage}`);
      
      const enrichedActivity = response.data.data.transactions.flatMap(transaction => {
        const marketplaceReceipt = transaction.receipts.find(receipt => receipt.marketplaceId);
        const marketplaceId = marketplaceReceipt ? marketplaceReceipt.marketplaceId : null;

        return transaction.contract.map(contract => ({
          ...transaction,
          contract: contract,
          marketplaceId
        }));
      });
  
      setActivity(enrichedActivity);
      setTotalActivityPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching activity data:', error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const limit = 100;
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=${limit}&type=17&status=success&asset=KLV&page=${transactionPage}`);
      
      const filteredTransactions = response.data.data.transactions.filter(transaction => {
        const isMarketBuy = transaction.contract.some(contract => 
          contract.type === 17 && contract.parameter.buyType === "MarketBuy"
        );

        const includesCollectionId = transaction.receipts.some(receipt => 
          receipt.assetId && receipt.assetId.startsWith(`${collectionId}/`)
        );

        return isMarketBuy && includesCollectionId;
      }).map(transaction => {
        const marketplaceReceipt = transaction.receipts.find(receipt => receipt.marketplaceId);
        const marketplaceId = marketplaceReceipt ? marketplaceReceipt.marketplaceId : null;
        return { ...transaction, marketplaceId };
      });

      setTransactions(filteredTransactions);
      setTotalTransactionPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  
  const handleActivityPrevPage = () => {
    if (activityPage > 1) setActivityPage(activityPage - 1);
  };

  const handleActivityNextPage = () => {
    if (activityPage < totalActivityPages) setActivityPage(activityPage + 1);
  };
  
  const handleTransactionPrevPage = () => {
    if (transactionPage > 1) setTransactionPage(transactionPage - 1);
  };
  
  const handleTransactionNextPage = () => {
    if (transactionPage < totalTransactionPages) setTransactionPage(transactionPage + 1);
  };

  const handleTraitChange = (traitType, value) => {
    setSelectedTraits(prevSelectedTraits => {
      const updatedSelectedTraits = { ...prevSelectedTraits };
      if (value === '') {
        delete updatedSelectedTraits[traitType];
      } else {
        updatedSelectedTraits[traitType] = value;
      }
      return updatedSelectedTraits;
    });
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
  };

  const handleActiveFilterChange = () => {
    setShowActiveOnly(!showActiveOnly);
  };
  
  const sortedNfts = [...nfts].sort((a, b) => {
    if (sortOrder === 'default') {
      return 0; // Do not apply sorting
    }
    
    if (!a.metadata || !b.metadata || !a.metadata.price || !b.metadata.price) {
      return 0;
    }
  
    const priceA = parseFloat(a.metadata.price.replace(/,/g, ''));
    const priceB = parseFloat(b.metadata.price.replace(/,/g, ''));
    
    return sortOrder === 'low-high' ? priceA - priceB : priceB - priceA;
  });
  

  const filteredNfts = sortedNfts.filter(nft => {
    if (!nft.metadata) return true; // Include NFTs without metadata
    if (showActiveOnly && nft.metadata.expired) return false; // Filter out expired NFTs if active filter is enabled
    return Object.entries(selectedTraits).every(([traitType, value]) =>
      nft.metadata.attributes?.some(attribute => attribute.trait_type === traitType && attribute.value === value)
    );
  });

  const toggleMarketplaceDropdown = () => {
    setMarketplaceDropdownVisible(!marketplaceDropdownVisible);
  };

  const handleMarketplaceSelect = (id) => {
    setSelectedMarketplace(id);
    setMarketplaceDropdownVisible(false); // Close dropdown after selection
  };

  return (
    <div>
      <div
        className="collection-header"
        style={{
          backgroundImage: bannerUri ? `url(${bannerUri})` : gradientBackground,
        }}
      >
        <div className="asset-name-overlay">
          <h3 className="asset-name">{assetName}</h3>
        </div>
        <div className="collection-title-and-logo">
          {logoUrl && <img src={logoUrl} alt="Collection Logo" className="collection-logo-header" />}
          <h1>{collectionId}</h1>
        </div>
        <div className="collection-uris">
          {telegramUri && (
            <a href={telegramUri} target="_blank" rel="noopener noreferrer">
              <img src={telegramLogo} alt="Telegram" className="uri-logo" />
            </a>
          )}
          {websiteUri && (
            <a href={websiteUri} target="_blank" rel="noopener noreferrer">
              <img src={websiteLogo} alt="Website" className="uri-logo" />
            </a>
          )}
          {discordUri && (
            <a href={discordUri} target="_blank" rel="noopener noreferrer">
              <img src={discordLogo} alt="Discord" className="uri-logo" />
            </a>
          )}
        </div>
        <div className="collection-stats">
          <div className="stat-item">
            <span>Total Volume</span>
            <span>{totalVolume.toFixed(2)} KLV</span>
          </div>
          <div className="stat-item">
            <span>Floor Price</span>
            <span>{floorPrice.toFixed(2)} KLV</span>
          </div>
          <div className="stat-item">
            <span>Items</span>
            <span>{items}</span>
          </div>
          <div className="stat-item">
            <span>Owners</span>
            <span>{owners}</span>
          </div>
        </div>
      </div>
  
      <div className="tab-navigation">
  <button
    className={`tab-item ${activeTab === 'items' ? 'active' : ''}`}
    onClick={() => setActiveTab('items')}
  >
    <i className="fas fa-th-large tab-icon"></i> Items
  </button>
  <button
    className={`tab-item ${activeTab === 'activity' ? 'active' : ''}`}
    onClick={() => setActiveTab('activity')}
  >
    <i className="fas fa-chart-line tab-icon"></i> Activity
  </button>
  {activeTab === 'activity' && (
    <>
      <button
        className={`sub-tab-item ${activeSubTab === 'sales' ? 'active' : ''}`}
        onClick={() => setActiveSubTab('sales')}
      >
        <i className="fas fa-list sub-tab-icon"></i> Listings
      </button>
      <button
        className={`sub-tab-item ${activeSubTab === 'listings' ? 'active' : ''}`}
        onClick={() => setActiveSubTab('listings')}
      >
        <i className="fas fa-shopping-cart sub-tab-icon"></i> Sales
      </button>
    </>
  )}

  {activeTab === 'items' && (
    <>
      {/* Show/Hide Filters Tab */}
      <button
        className={`tab-item ${filtersVisible ? 'active' : ''}`}
        onClick={() => setFiltersVisible(!filtersVisible)}
      >
        <i className="fas fa-filter tab-icon"></i>
        {filtersVisible ? 'Hide Filters' : 'Show Filters'}
      </button>

      {/* Marketplace Dropdown Tab */}
      <div className="marketplace-tab">
        <button
          className={`tab-item ${marketplaceDropdownVisible ? 'active' : ''}`}
          onClick={toggleMarketplaceDropdown}
        >
          <i className="fas fa-store tab-icon"></i> Marketplace{' '}
          <i className={`fas ${marketplaceDropdownVisible ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
        </button>
        <div className={`marketplace-dropdown-content ${marketplaceDropdownVisible ? 'active' : ''}`}>
          {Object.entries(marketplaceNames).map(([id, name]) => (
            <div key={id} className="marketplace-dropdown-item" onClick={() => handleMarketplaceSelect(id)}>
              <img src={marketplaceImages[id]} alt={name} className="marketplace-image" />
              {name}
            </div>
          ))}
        </div>
      </div>
    </>
  )}
</div>

  
      {activeTab === 'items' && (
        <div className="content-container">
          {filtersVisible && (
            <div className="filters">
              {Object.entries(traits).map(([traitType, values]) => (
                <div key={traitType} className="filter-dropdown">
                  <label>{traitType}</label>
                  <select
                    value={selectedTraits[traitType] || ''}
                    onChange={(e) => handleTraitChange(traitType, e.target.value)}
                  >
                    <option value="">All</option>
                    {Array.from(values).map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              <div className="sort-dropdown">
                <label>Sort By</label>
                <select
                  value={sortOrder}
                  onChange={(e) => handleSortOrderChange(e.target.value)}
                >
                  <option value="default">Default</option>
                  <option value="low-high">Price: Low to High</option>
                  <option value="high-low">Price: High to Low</option>
                </select>
              </div>
              <div className="filter-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={showActiveOnly}
                    onChange={handleActiveFilterChange}
                  />
                  Show Active Only
                </label>
              </div>
            </div>
          )}
  
          <div className="nft-cards">
            {loading ? (
              <Spinner />
            ) : filteredNfts.length > 0 ? (
              filteredNfts.map((nft, index) => (
                <div
                  className={`nft-card ${nft.metadata && nft.metadata.expired ? 'expired' : ''}`}
                  key={index}
                >
                  <Link
                    to={`/collections/${collectionId}/${nft.nonce}`}
                    state={{ nft: nft.metadata }}
                  >
                    <div
                      className="nft-card-content"
                      style={{
                        filter: nft.metadata && nft.metadata.expired ? 'grayscale(100%)' : 'none',
                      }}
                    >
                      <h3>#{nft.nonce}</h3>
                      {nft.loadingMetadata ? (
                        <Spinner />
                      ) : nft.metadata ? (
                        <>
                          <img
                            src={nft.metadata.image}
                            alt={nft.metadata.name}
                            loading="lazy"
                          />
                          <div className="nft-price">
                            {nft.metadata.price
                              ? `${nft.metadata.price} KLV`
                              : 'Price Not Available'}
                          </div>
                          {nft.metadata.expired && <div className="ribbon">Expired</div>}
                        </>
                      ) : (
                        <p>Metadata not available</p>
                      )}
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="no-items-message">No items for sale</p>
            )}
          </div>
        </div>
      )}
  
      {activeTab === 'activity' && (
        <div className="activity-content">
          {activeSubTab === 'sales' && (
            <>
              {activity.length === 0 ? (
                <p>No sales yet. Go grab some!</p>
              ) : (
                activity.map((item, index) => {
                  const sellContract = item.contract;
                  const assetId = sellContract.parameter.assetId;
                  const listedPrice = sellContract.parameter.price / 1000000;
                  const date = new Date(item.timestamp * 1000);
                  const sender = item.sender;
                  const assetNumber = assetId.split('/')[1];
                  const modifiedImageUrl = imageUrl.replace(
                    /\/\d+\.png$/,
                    `/${assetNumber}.png`
                  );
                  const nftId = assetNumber;
                  const marketplaceName =
                    marketplaceNames[item.marketplaceId] || item.marketplaceId;
                  const marketplaceClassName = getMarketplaceClassName(item.marketplaceId);
  
                  return (
                    <Link
                      to={`/collections/${collectionId}/${nftId}`}
                      key={index}
                      className="activity-item-link"
                    >
                      <div className="activity-item">
                        <img
                          src={modifiedImageUrl}
                          alt={`Asset ${assetId}`}
                          className="asset-image"
                        />
                        <p>
                          {date.toLocaleDateString()} {date.toLocaleTimeString()} -{' '}
                          {assetNumber} Listed for{' '}
                          <span className="listed-price">
                            {listedPrice.toLocaleString('en-US')} KLV
                          </span>{' '}
                          by {sender === walletAddress ? 'You' : `klv1...${sender.slice(-4)}`}{' '}
                          on <span className={marketplaceClassName}>{marketplaceName}</span>
                        </p>
                      </div>
                    </Link>
                  );
                })
              )}
              <div className="pagination">
                <button onClick={handleActivityPrevPage} disabled={activityPage <= 1}>
                  Previous
                </button>
                <span>
                  Page {activityPage} of {totalActivityPages}
                </span>
                <button
                  onClick={handleActivityNextPage}
                  disabled={activityPage >= totalActivityPages}
                >
                  Next
                </button>
              </div>
            </>
          )}
  
          {activeSubTab === 'listings' && (
            <div className="listing-content">
              {transactions.length === 0 ? (
                <p>No sales, or try to move to the next page.</p>
              ) : (
                transactions.map((transaction, index) => {
                  const buyContract = transaction.contract.find(
                    (contract) => contract.typeString === 'BuyContractType'
                  );
                  const listedPrice = buyContract
                    ? buyContract.parameter.amount / 1000000
                    : 'N/A';
                  const assetReceipt = transaction.receipts.find(
                    (receipt) =>
                      receipt.assetId && receipt.assetId.startsWith(`${collectionId}/`)
                  );
                  const assetId = assetReceipt ? assetReceipt.assetId : 'N/A';
                  const assetNumber = assetId.split('/')[1] || 'N/A';
                  const date = new Date(transaction.timestamp * 1000);
                  const sender = transaction.sender || 'N/A';
                  const modifiedImageUrl = imageUrl.replace(
                    /\/\d+\.png$/,
                    `/${assetNumber}.png`
                  );
                  const marketplaceId = transaction.marketplaceId || 'defaultMarketplaceId';
                  const marketplaceClassName = getMarketplaceClassName(marketplaceId);
                  const marketplaceName =
                    marketplaceNames[marketplaceId] || 'Unknown Marketplace';
  
                  return (
                    <Link
                      to={`/collections/${collectionId}/${assetNumber}`}
                      key={index}
                      className="activity-item-link"
                    >
                      <div className="activity-item">
                        <img
                          src={modifiedImageUrl}
                          alt={`Asset ${assetId}`}
                          className="asset-image"
                        />
                        <p>
                          {date.toLocaleDateString()} {date.toLocaleTimeString()} -{' '}
                          {assetNumber} Bought for{' '}
                          <span className="listed-price">
                            {listedPrice.toLocaleString('en-US')} KLV
                          </span>{' '}
                          by {sender === walletAddress ? 'You' : `klv1...${sender.slice(-4)}`}{' '}
                          on <span className={marketplaceClassName}>{marketplaceName}</span>
                        </p>
                      </div>
                    </Link>
                  );
                })
              )}
              <div className="pagination">
                <button onClick={handleTransactionPrevPage} disabled={transactionPage <= 1}>
                  Previous
                </button>
                <span>
                  Page {transactionPage} of {totalTransactionPages}
                </span>
                <button
                  onClick={handleTransactionNextPage}
                  disabled={transactionPage >= totalTransactionPages}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      )}
  
      {activeTab === 'items' && (
        <div className="pagination">
          <button onClick={handlePrevPage} disabled={currentPage <= 1}>
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={currentPage >= totalPages}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

const getMarketplaceClassName = (marketplaceId) => {
  switch (marketplaceId) {
    case '7c353c02770da029':
      return 'marketplace-sky-gallery';
    case 'd2a04fe890a6edda':
      return 'marketplace-nft-art-gallery';
    case 'd4f2bab340c55fde':
      return 'marketplace-klevernft';
    case '2936933ee43db509':
      return 'marketplace-minthree';
    case '417b70c0eb7a33cb':
      return 'marketplace-rare-canvas';
    case 'aa68ad853a09988c':
      return 'marketplace-xport';
    case '116056b257d9f6d5':
      return 'marketplace-kDEX';
      case '81376f526cf47730':
      return 'marketplace-world';
    default:
      return '';
  }
};

export default CollectionDetailPage;
