import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import Spinner from '../components/Spinner';
import { web, TransactionType } from '@klever/sdk-web';

import skyGalleryImage from '../assets/skygallery.png';
import klevernftImage from '../assets/klever.png';
import minthreeImage from '../assets/minthree.png';
import xportImage from '../assets/xport.png';
import RareCanvasImage from '../assets/logoicon2.png';
import world from '../assets/world.png';
import artGallery from '../assets/artgallery.png';
import '../styles/WalletListingsPage.css';

const marketplaceMapping = {
  '7c353c02770da029': 'Sky Gallery',
  'd4f2bab340c55fde': 'KleverNFT',
  '2936933ee43db509': 'Minthree',
  'd2a04fe890a6edda': 'NFT Art Gallery',
  'aa68ad853a09988c': 'Xport',
  '417b70c0eb7a33cb': 'Rare Canvas',
  '81376f526cf47730': 'World dex'
};

const marketplaceImages = {
  'Sky Gallery': skyGalleryImage,
  'KleverNFT': klevernftImage,
  'Minthree': minthreeImage,
  'Xport': xportImage,
  'Rare Canvas': RareCanvasImage,
  'World dex': world,
  'NFT Art Gallery': artGallery,
};

const statusIcons = {
  'Active': 'fas fa-check-circle',
  'Cancelled': 'fas fa-times-circle',
  'Claimed': 'fas fa-clipboard-check',
  'Sold': 'fas fa-dollar-sign',
  'Expired': 'fas fa-hourglass-end'
};

const WalletListingsPage = () => {
  const { walletAddress, useKdaFee, selectedKda } = useWallet();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [expiredOrderCount, setExpiredOrderCount] = useState(0);
  const [activeOrderCount, setActiveOrderCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [modalCount, setModalCount] = useState(0);
  const [modalCost, setModalCost] = useState(0);
  const [ordersToClaim, setOrdersToClaim] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState('');

  const fetchOrderStatus = async (orderId, endTime) => {
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=20&page=1&orderid=${orderId}`);
      const transactions = response.data.data.transactions;

      let status = 'Active'; // Default status
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      transactions.sort((a, b) => a.blockNum - b.blockNum); // Ensure chronological order

      for (const transaction of transactions) {
        // Check if the order has been cancelled
        if (transaction.contract.some(contract => contract.typeString === "CancelMarketOrderContractType" && contract.parameter.orderID === orderId)) {
          status = 'Cancelled';
          break; // Cancelled is a final status
        }
        // Check if the order has been claimed
        else if (transaction.contract.some(contract => contract.typeString === "ClaimContractType" && contract.parameter.id === orderId)) {
          status = 'Claimed';
          break; // Claimed is a final status
        }
        // Check if the transaction involved a BuyContractType
        else if (transaction.contract.some(contract => contract.typeString === "BuyContractType" && contract.parameter.id === orderId)) {
          status = 'Sold';
          break; // Sold is a final status
        }
      }

      // If current time is past end time and no overriding transactions, set to Expired
      if (currentTime > endTime && status === 'Active') {
        status = 'Expired';
      }

      return status;
    } catch (error) {
      console.error('Error fetching order status for order ID:', orderId, error);
      return 'Error'; // Returning 'Error' in case of a fetch failure
    }
  };

  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=20&page=${page}&type=18&status=success&fromAddress=${walletAddress}`);
      const transformedTransactions = await Promise.all(response.data.data.transactions.flatMap(async (transaction) => {
        const sellContracts = transaction.contract.filter(contract => contract.typeString === "SellContractType");
        if (sellContracts.length > 0) {
          const transformedContracts = await Promise.all(sellContracts.map(async (sellContract, index) => {
            const [collectionId, nftNonce] = sellContract.parameter.assetId.split('/');
            const price = sellContract.parameter.price / 1000000; // Convert to KLV
            const marketplaceName = marketplaceMapping[sellContract.parameter.marketplaceID] || 'Unknown Marketplace';
            const sellReceipt = transaction.receipts.find(receipt => receipt.typeString === "Sell" && receipt.cID === index);
            const orderId = sellReceipt ? sellReceipt.orderId : 'No Order ID';
            const status = await fetchOrderStatus(orderId, sellContract.parameter.endTime); // Pass endTime here
            const metadata = await fetchMetadata(collectionId, nftNonce);
            const imageUrl = metadata?.image || null;

            return { ...transaction, collectionId, nftNonce, price, marketplaceName, imageUrl, orderId, status };
          }));
          return transformedContracts;
        }
        return [];
      }));

      const validTransactions = transformedTransactions.flat().filter(tx => tx !== null);
      setTransactions(validTransactions);
      setTotalPages(response.data.pagination.totalPages);

      const expiredCount = validTransactions.filter(tx => tx.status === 'Expired').length;
      setExpiredOrderCount(expiredCount);

      const activeCount = validTransactions.filter(tx => tx.status === 'Active').length;
      setActiveOrderCount(activeCount);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMetadata = async (collectionId, nftId) => {
    try {
      const assetResponse = await axios.get('https://api.mainnet.klever.finance/v1.0/assets/list?limit=100');
      const assets = assetResponse.data.data.assets;
      const matchingAsset = assets.find(asset => asset.assetId === collectionId);

      if (!matchingAsset) {
        console.error('Matching asset not found for collection ID:', collectionId);
        return;
      }

      const metadataObject = matchingAsset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
      let metadataUri = metadataObject ? metadataObject.value : null;

      // Handling for Love Monsters collection
      if (metadataUri && metadataUri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") {
        metadataUri = `https://ipfs.io/ipfs/${metadataUri}/${nftId}.json`;
      }
      // Handling for other collections
      else if (metadataUri) {
        if (metadataUri.includes("klever-mint.mypinata.cloud")) {
          metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        metadataUri = `${metadataUri}/${nftId}`;
      }

      if (!metadataUri) {
        console.error('Metadata URI not found for', nftId);
        return null;
      }

      try {
        const metadataResponse = await axios.get(metadataUri);
        const metadata = metadataResponse.data;

        // Replace image URL for Love Monsters collection
        if (metadata.image && metadata.image.includes("www.lovemonsternft.com")) {
          const imageName = metadata.image.split('/').pop();
          metadata.image = `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
        } else if (metadata.image && metadata.image.includes("klever-mint.mypinata.cloud")) {
          metadata.image = metadata.image.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }

        return metadata;
      } catch (error) {
        console.error('Primary fetch failed, trying fallback for', nftId);
        const fallbackUri = `https://${metadataUri}.ipfs.w3s.link/${nftId}`;
        const fallbackResponse = await axios.get(fallbackUri);
        return fallbackResponse.data;
      }
    } catch (error) {
      console.error(`Error fetching metadata for collection ${collectionId} and nonce ${nftId}:`, error);
      return null;
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  const openModal = (action, count, cost, orders = []) => {
    setModalAction(action);
    setModalCount(count);
    setModalCost(cost);
    setOrdersToClaim(orders);
    setModalVisible(true);
  };

  const handleClaimAll = () => {
    const expiredOrders = transactions.filter(tx => tx.status === 'Expired').slice(0, 20).map(tx => tx.orderId);
    const cost = expiredOrders.length * 3; // Example cost calculation
    openModal('claim', expiredOrders.length, cost, expiredOrders);
  };

  const handleDelistAll = () => {
    const activeOrders = transactions.filter(tx => tx.status === 'Active').slice(0, 20).map(tx => tx.orderId);
    const cost = activeOrders.length * 52 ; // Example cost calculation
    openModal('delist', activeOrders.length, cost, activeOrders);
  };

  const handleModalConfirm = async () => {
    if (modalAction === 'claim') {
      await claimAllNfts(ordersToClaim);
    } else if (modalAction === 'delist') {
      await delistAllOrders(ordersToClaim);
    }
  
    // Wait for 2 seconds before closing the modal
    setTimeout(() => {
      setModalVisible(false);
    }, 2000);
  };

  const claimAllNfts = useCallback(async (orderIds) => {
    const contracts = orderIds.map(orderId => ({
      type: 9, // Type for claiming an NFT
      payload: {
        claimType: 2, // Assuming this is the correct claim type
        id: orderId,
      },
    }));

    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }

    try {
      const unsignedTx = await web.buildTransaction(contracts, undefined, txOptions); // Add the txOptions

      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Claim NFT transaction response:', response);

      // Handle the UI updates and state changes as needed
      setTransactionStatus("Claim transaction completed!");  // Set the status
      // ... other UI updates

    } catch (error) {
      console.error('Error in claim NFT transaction:', error);
      setTransactionStatus("Claim transaction failed!");  // Set the status
      // ... other UI updates
    }
  }, [useKdaFee, selectedKda]);

  const delistAllOrders = useCallback(async (orderIds) => {
    const contracts = orderIds.map(orderId => ({
      type: 19, // Transaction type for cancelling an order
      payload: {
        claimType: 2, // Assuming this is the correct claim type
        orderId: orderId,
      },
    }));

    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }

    try {
      const unsignedTx = await web.buildTransaction(contracts, undefined, txOptions); // Add the txOptions

      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Cancel order transaction response:', response);

      // Handle the UI updates and state changes as needed
      setTransactionStatus("Order cancellation completed!");  // Set the status
      // ... other UI updates

    } catch (error) {
      console.error('Error in cancel order transaction:', error);
      setTransactionStatus("Order cancellation failed!");  // Set the status
      // ... other UI updates
    }
  }, [useKdaFee, selectedKda]);

  return (
    <div>
      {loading ? <Spinner /> : (
        <div>
          {/* Button Group styled like tabs */}
          <div className="button-group">
            {expiredOrderCount > 0 && (
              <button onClick={handleClaimAll} className={`tab-button ${expiredOrderCount > 0 ? 'active' : ''}`}>
                <i className="fas fa-clipboard-check"></i> Claim all ({expiredOrderCount})
              </button>
            )}
            {activeOrderCount > 0 && (
              <button onClick={handleDelistAll} className={`tab-button ${activeOrderCount > 0 ? 'active' : ''}`}>
                <i className="fas fa-times-circle"></i> Delist all ({activeOrderCount})
              </button>
            )}
          </div>
          
          {transactions.map((transaction, index) => (
            <Link to={`/collections/${transaction.collectionId}/${transaction.nftNonce}`} key={index} className="activity-item-link">
              <div className="activity-item">
                {transaction.imageUrl && (
                  <img src={transaction.imageUrl} alt={`NFT ${transaction.collectionId}/${transaction.nftNonce}`} className="listingsnftimage" />
                )}
                <div className="activity-details">
                  <p>
                    <span className="date">{formatDate(transaction.timestamp)}</span> {' '}
                    You listed <span className="asset-id">{transaction.collectionId}/{transaction.nftNonce}</span> {' '}
                    for <span className="listed-price">{transaction.price.toLocaleString()} KLV</span> {' '}
                    on <span className="marketplace-info">
                      <span className="marketplace">{transaction.marketplaceName}</span>
                      {marketplaceImages[transaction.marketplaceName] && (
                        <img src={marketplaceImages[transaction.marketplaceName]} alt={transaction.marketplaceName} className="marketplace-image" />
                      )}
                    </span>
                  </p>
                  <span className={`order-status status-${transaction.status.toLowerCase()}`}>
                    <i className={statusIcons[transaction.status]}></i> {transaction.status}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage <= 1}>Previous</button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage >= totalPages}>Next</button>
      </div>
      {modalVisible && (
        <DelistingConfirmationModal
          action={modalAction}
          count={modalCount}
          cost={modalCost}
          onCancel={() => setModalVisible(false)}
          onConfirm={handleModalConfirm}
        />
      )}
    </div>
  );
  
};

const DelistingConfirmationModal = ({ action, count, cost, onCancel, onConfirm }) => (
  <div className="delist-modal-overlay">
    <div className="delist-modal-content">
      <h2>Are you sure you want to {action} {count} NFTs?</h2>
      <p>Cost of {action}ing: {cost} KLV</p>
      <div className="delist-modal-buttons">
        <button onClick={onCancel}>Cancel</button>
        <button onClick={onConfirm}>Confirm</button>
      </div>
    </div>
  </div>
);

export default WalletListingsPage;
