import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import '../styles/CollectionDetailPage.css';
import Spinner from '../components/Spinner';

import skyGalleryImage from '../assets/skygallery.png'; // Adjust the path accordingly
import klevernftImage from '../assets/klever.png'; // Adjust the path accordingly
import minthreeImage from '../assets/minthree.png'; // Adjust the path accordingly
import xportImage from '../assets/xport.png'; // Adjust the path accordingly
import RareCanvasImage from '../assets/logoicon2.png';
import kDEXImage from '../assets/kdex.png';
import world from '../assets/world.png';
import artGallery from '../assets/artgallery.png';

const marketplaceMapping = {
    '7c353c02770da029': 'Sky Gallery',
    'd4f2bab340c55fde': 'KleverNFT',
    '2936933ee43db509': 'Minthree',
    'd2a04fe890a6edda': 'NFT Art Gallery',
    'aa68ad853a09988c': 'Xport',
    '417b70c0eb7a33cb': 'Rare Canvas',
    '116056b257d9f6d5': 'kDEX',
    '81376f526cf47730': 'World dex'
};

const marketplaceImages = {
    'Sky Gallery': skyGalleryImage,
    'KleverNFT': klevernftImage,
    'Minthree': minthreeImage,
    'Xport': xportImage,
    'Rare Canvas': RareCanvasImage,
    'kDEX': kDEXImage,
    'World dex': world,
    'NFT Art Gallery': artGallery,
};

const WalletPurchasesPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { walletAddress } = useWallet();

  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/transaction/list?limit=100&page=${page}&type=17&buyType=1&status=success&fromAddress=${walletAddress}`);
      const transformedTransactions = await Promise.all(response.data.data.transactions.map(async (transaction) => {
        const receiptWithAssetId = transaction.receipts.find(receipt => 
          receipt.typeString === "KAppTransfer" && receipt.assetId !== "KLV"
        );

        if (receiptWithAssetId) {
          const { assetId, marketplaceId } = receiptWithAssetId;
          const price = transaction.contract[0].parameter.amount / 1000000; // Convert to KLV
          const marketplaceName = marketplaceMapping[marketplaceId] || 'Unknown Marketplace';

          // Fetch NFT metadata
          const [collectionId, nftNonce] = assetId.split('/');
          const metadata = await fetchMetadata(collectionId, nftNonce);
          const imageUrl = metadata?.image || null;

          return { ...transaction, assetId, price, marketplaceName, imageUrl };
        }
        return null;
      }));

      const transactionsData = transformedTransactions.filter(t => t !== null);
      setTransactions(transactionsData);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMetadata = async (collectionId, nftId) => {
    try {
      // Fetch data from both page 1 and page 2
      const [assetResponsePage1, assetResponsePage2] = await Promise.all([
        axios.get(`https://api.mainnet.klever.finance/v1.0/assets/list?page=1&limit=100`),
        axios.get(`https://api.mainnet.klever.finance/v1.0/assets/list?page=2&limit=100`)
      ]);
  
      // Combine assets from both pages
      const assets = [
        ...assetResponsePage1.data.data.assets,
        ...assetResponsePage2.data.data.assets
      ];
  
      // Find the matching asset based on collectionId
      const matchingAsset = assets.find(asset => asset.assetId === collectionId);
  
      if (!matchingAsset) {
        console.error('Matching asset not found for collection ID:', collectionId);
        return null;
      }
  
      const metadataObject = matchingAsset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
      let metadataUri = metadataObject ? metadataObject.value : null;
  
      // Process metadata URI
      if (metadataUri) {
        if (metadataUri.includes("klever-mint.mypinata.cloud")) {
          metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        metadataUri = `${metadataUri}/${nftId}`;
      }
  
      if (!metadataUri) {
        console.error('Metadata URI not found for', nftId);
        return null;
      }
  
      try {
        const metadataResponse = await axios.get(metadataUri);
        const metadata = metadataResponse.data;
  
        // Handle specific image URL replacements
        if (metadata.image && metadata.image.includes("www.lovemonsternft.com")) {
          const imageName = metadata.image.split('/').pop();
          metadata.image = `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
        } else if (metadata.image && metadata.image.includes("klever-mint.mypinata.cloud")) {
          metadata.image = metadata.image.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
  
        return metadata;
      } catch (error) {
        console.error('Primary fetch failed, trying fallback for', nftId);
        const fallbackUri = `https://${metadataUri}.ipfs.w3s.link/${nftId}`;
        const fallbackResponse = await axios.get(fallbackUri);
        return fallbackResponse.data;
      }
    } catch (error) {
      console.error(`Error fetching metadata for collection ${collectionId} and nonce ${nftId}:`, error);
      return null;
    }
  };
  

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  return (
    <div>
      {loading ? <Spinner /> : 
        <div>
          {transactions.map((transaction, index) => {
            const marketplaceImage = marketplaceImages[transaction.marketplaceName];

            return (
              <Link to={`/collections/${transaction.assetId}`} key={index} className="activity-item-link">
                <div className="activity-item">
                  {transaction.imageUrl && (
                    <img 
                      src={transaction.imageUrl} 
                      alt={`NFT ${transaction.assetId}`}
                      className="listingsnftimage"
                    />
                  )}
                  <p>
                    <span className="date">{formatDate(transaction.timestamp)}</span>{' '}
                    You bought <span className="asset-id">{transaction.assetId}</span>{' '} 
                    for <span className="listed-price">{transaction.price.toLocaleString()} KLV</span>{' '}
                    on <span className="marketplace-info">
                      <span className="marketplace">{transaction.marketplaceName}</span>
                      {marketplaceImage && (
                        <img 
                          src={marketplaceImage} 
                          alt={transaction.marketplaceName} 
                          className="marketplace-image"
                        />
                      )}
                    </span>
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      }
      
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage <= 1}>Previous</button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage >= totalPages}>Next</button>
      </div>
    </div>
  );
};

export default WalletPurchasesPage;
